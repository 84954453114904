import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import classNames from 'classnames'

const BaseModal = ({ children, open, onOpen, onClose }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    if (isOpen) {
      document.querySelector('html').classList.add('overflow-hidden')
      if (onOpen) {
        onOpen()
      }
    } else {
      document.querySelector('html').classList.remove('overflow-hidden')
      if (onClose) {
        onClose()
      }
    }
  }, [isOpen])

  const handleEscape = event => {
    if (event.keyCode === 27) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleEscape, false)
    }

    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [handleEscape, isOpen])

  const getRootElement = () => {
    let rootElement = document.querySelector('.js-modal-root')

    if (!rootElement) {
      rootElement = document.createElement('div')
      rootElement.classList.add('js-modal-root')
      document.body.appendChild(rootElement)
    }

    return rootElement
  }

  return isOpen
    ? createPortal(
      <div
        className={
          classNames([
            'fixed',
            'top-0',
            'left-0',
            'w-screen',
            'h-screen',
            'bg-black',
            'bg-opacity-25',
            'flex',
            'items-center',
            'justify-center',
            'overflow-auto'
          ])
        }
        onClick={() => setIsOpen(false)}>
        <div
          className={'max-w-screen max-h-screen'}
          onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
          {children}
        </div>
      </div>,
      getRootElement()
    )
    : null
}

BaseModal.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onclose: PropTypes.func
}

const Modal = dynamic(() => Promise.resolve(BaseModal), {
  ssr: false
})

export default Modal
