import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '../../../styles/bantrab/components/spinner.module.scss'

const Spinner = ({ message, color = 'yellow-500', ...attrs }) => {
  attrs.className = classNames(
    attrs.className,
    [
      'flex',
      'flex-col',
      'justify-center',
      'items-center'
    ]
  )

  return <div
    {...attrs}>
    <div
      className={styles.spinner}>
      <div
        className={`bg-${color}`}></div>
      <div
        className={`bg-${color}`}></div>
      <div
        className={`bg-${color}`}></div>
      <div
        className={`bg-${color}`}></div>
    </div>
    {message}
  </div>
}

Spinner.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string
}

export default Spinner
