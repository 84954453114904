import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import Spinner from '../../spinner'

import { useAuth } from '../../../../hooks/auth'
import { formatURI } from '../../../../api/common/utils'
import { getUser, createEnrollment } from '../../../../api/bantrab'

import ArrowRight from '../../icon/arrow-right.svg'
import BannuLogo from '../../../../public/img/bantrab/logo-bannu-02.svg'
import Typography from '../../typography'

const Action = ({ course, integrationSettings, automaticRedirect, ...attrs }) => {
  const auth = useAuth()
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(false)
  const [isRegistered, setIsRegistered] = useState(false)
  const [isRedirected, setIsRedirected] = useState(false)
  const [isVisible, setIsVisible] = useState(true);

  const handleClick = async (event) => {
    event.preventDefault()

    if (!auth.isAuthenticated) {
      router.push({
        pathname: '/auth/signin',
        query: {
          nexturl: router.asPath
        }
      })
    } else {
      setIsLoading(true)
      redirect(true);
      setIsLoading(false)
    }
  }

  const redirect = async (isNewPanel) => {
    setIsRedirected(true)
    const enrollment = await auth.createEnrollment({ course: course.id }, createEnrollment)
    auth.getUser({}, getUser)

    const baseURL = 'https://www.crehana.com'
    const basePath = `/api/rest/org/${integrationSettings.organizationSlug}/sso-auth/`

    const query = {
      api_key: integrationSettings.apiKey,
      token: enrollment.token,
      next_url: enrollment.url
    }

    const url = formatURI(baseURL, basePath, query)

    if (typeof window !== 'undefined') {
      if(isNewPanel){
        const newWindow = window.open(url, '_blank')
        newWindow && newWindow.focus()
      }else{
        window.location.href = url;
      }
    }
  }

  useEffect(() => {
    if (auth && auth.isAuthenticated) {
      const { user } = auth
      if (user) {
        const { achievements = [] } = user
        setIsRegistered(achievements.find(item => item.course.course.id === course.crehanaCourseId) !== undefined)
        if(automaticRedirect && automaticRedirect === "true" && !isRedirected){
          redirect(true)
        }
      }
    }
  }, [auth])

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 8000);

    return () => {
      clearTimeout(timer);
    };
  }, []);


  if (course.provider === 'lachiripa') {
    return < Link
      href={`/cursos/${course.id}/${course.meta.slug}`
      }>
      <a
        className={classNames([
          'relative',
          'self-end',
          'block',
          'w-full',
          'flex',
          'flex-row',
          'justify-center',
          'text-center',
          'bg-green-500',
          'text-white',
          'py-3',
          'hover:bg-green-400',
          'active:bg-green-600'
        ])}
        style={{
          borderRadius: '0 1.5rem 1.5rem 1.5rem',
          minHeight: '3rem'
        }}
        target={'_self'}>
        {
          course.lachiripaType === 'serie'
            ? <>Ver episodio</>
            : course.lachiripaType === 'capsule'
              ? <>Ver capsula</>
              : <>Ir al curso</>
        }

        <ArrowRight
          width={24}
          height={24}
          className={'ml-2'} />
      </a>
    </Link >
  }

  if (auth.isAuthenticated && automaticRedirect && automaticRedirect === "true") {
    return isVisible ? (
      <div
        className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)', // Fondo blanco con opacidad
          position: 'fixed',
        }}
      >
        <div className="text-black p-4 bg-white rounded-lg shadow-lg">

          <BannuLogo
            className={'block mx-auto mb-6'}
            height={40} />

          <Typography
            size={'five'}
            weight={'black'}
            className={'text-center text-green-600 mb-1'}>
            Bienvenido de vuelta
          </Typography>

          <Typography
            size={'thee'}
            weight={'regular'}
            className={'text-center text-green-600 mb-4'}>
             Estamos redireccionando a Crehana...
          </Typography>
        </div>
      </div>
    ): null;
  }

  return <Link
    href={`/cursos/${course.id}/${course.meta.slug}`}>
    <a
      className={classNames([
        'relative',
        'self-end',
        'block',
        'w-full',
        'flex',
        'flex-row',
        'justify-center',
        'text-center',
        'bg-green-500',
        'text-white',
        'py-3',
        'hover:bg-green-400',
        'active:bg-green-600',
        isLoading ? 'cursor-not-allowed' : ''
      ])}
      style={{
        borderRadius: '0 1.5rem 1.5rem 1.5rem',
        minHeight: '3rem'
      }}
      onClick={isLoading ? (event) => { event.preventDefault(); } : handleClick}>
      {
        isLoading
          ? <Spinner
            color={'white'}
            className={'absolute -top-4 -bottom-4 left-0 rigth-0 w-full cursor-not-allowed'} />
          : <>
            {
              isRegistered
                ? <>Ir al curso</>
                : <>Únete ahora</>
            }
            <ArrowRight
              width={24}
              height={24}
              className={'ml-2'} />
          </>
      }
    </a>
  </Link>
}

Action.propTypes = {
  course: PropTypes.object,
  integrationSettings: PropTypes.object,
  automaticRedirect: PropTypes.string
}

export default Action
