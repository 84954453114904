import PropTypes from 'prop-types'

import Typography from '../../typography'
import { getAuthor } from '../utils'

import Action from '../action'

const Body = ({ course, integrationSettings, ...attrs }) => {
  const author = getAuthor(course)
  console.log(author)
  return <div
    className={'p-4'}>
    <div className={'grid grid-cols-1 sm:grid-cols-2 gap-8'}>
      <div>
        <Typography
          size={'four'}
          weight={'black'}
          className={'mb-3'}>
          {course.title}
        </Typography>
        <Typography
          size={'three'}
          weight={'regular'}>
          Por{' '}
          <Typography
            tag={'span'}
            size={'three'}
            weight={'medium'}>
            {author ? author.fullName : 'El equipo Crehana'}
          </Typography>
        </Typography>
      </div>
      <div>
        <Action course={course} integrationSettings={integrationSettings} />
      </div>
    </div>
  </div>
}

Body.propTypes = {
  course: PropTypes.object
}

export default Body
