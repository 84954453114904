import PropTypes from 'prop-types'
import classNames from 'classnames'
import { get } from 'lodash'

import Embed from './embed'
import Video from './video'
import Body from './body'

const Preview = ({ course, integrationSettings, showBody = true, ...attrs }) => {
  const hasEmbed = !!get(course, 'previewEmbed', false)
  const hasVideo = hasEmbed ? false : !!get(course, 'previewVideo', false)

  attrs.className = classNames(
    attrs.className,
    [
      'flex',
      'flex-col',
      'relative',
      'bg-white',
      'md:m-4'
    ]
  )
  return <div
    {...attrs}>
    {hasEmbed ? <Embed course={course} /> : null}
    {hasVideo ? <Video course={course} /> : null}
    {showBody ? <Body course={course} integrationSettings={integrationSettings} /> : null}
  </div>
}

Preview.propTypes = {
  course: PropTypes.object,
  integrationSettings: PropTypes.object,
  showBody: PropTypes.bool
}

export default Preview
