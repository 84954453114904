import PropTypes from 'prop-types'
import classNames from 'classnames'
import { get } from 'lodash'

const Video = ({ course, videoAttrs, containerVideoAttrs, ...attrs }) => {
  const videoURL = get(course, 'previewVideo.file', '')
  const thumbnailURL = get(course, 'previewVideo.thumbnail', '')

  attrs.className = classNames(
    attrs.className,
    [
      'relative'
    ]
  )
  attrs.style = {
    ...{
      width: '100%',
      height: '0',
      paddingBottom: '56.25%'
    },
    ...(attrs.style || {})
  }
  return <div
    {...attrs}>
    <div
      {...containerVideoAttrs}
      className={'absolute top-0 left-0 right-0 bottom-0'}>
      <video
        {...videoAttrs}
        className={'relative h-full w-full outline-none'}
        poster={thumbnailURL}
        autoPlay={false}
        controls>
        <source
          src={videoURL}
          type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
}

Video.propTypes = {
  course: PropTypes.object,
  videoAttrs: PropTypes.object,
  containerVideoAttrs: PropTypes.object
}

export default Video
