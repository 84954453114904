import { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Modal from '../../modal'
import Play from '../../../common/icon/play.svg'
import Preview from './index'

const Trigger = ({ course, integrationSettings, ...attrs }) => {
  const [showContent, setShowContent] = useState(false)
  attrs.className = classNames(
    attrs.className,
    [
      'rounded-full',
      'bg-green-500',
      'opacity-75',
      'flex',
      'items-center',
      'justify-center',
      'cursor-pointer'
    ]
  )
  attrs.style = {
    ...{
      width: '3.5rem',
      height: '3.5rem'
    },
    ...(attrs.style || {})
  }
  return <>
    <div
      {...attrs}
      onClick={() => setShowContent(true)}>
      <Play
        className={'text-white'}
        height={24}
        width={24} />
    </div>
    <Modal
      open={showContent}
      onClose={() => setShowContent(false)}>
      <Preview
        course={course}
        integrationSettings={integrationSettings}
        className={'w-full'}
        style={{
          maxWidth: '500px'
        }} />
    </Modal>
  </>
}

Trigger.propTypes = {
  course: PropTypes.object,
  integrationSettings: PropTypes.object
}

export default Trigger
