export const getCategory = (course) => {
  return (course.courseCategories && course.courseCategories.length > 0) ? course.courseCategories[0].category : null
}

export const getAuthor = (course) => {
  return (course.courseAuthors && course.courseAuthors.length > 0) ? course.courseAuthors[0].author : null
}

export const getDuration = (course, onlyMinutes = false) => {
  let duration = (
    course.metadata &&
    course.metadata.crehanaCourse &&
    course.metadata.crehanaCourse.duration)
    ? course.metadata.crehanaCourse.duration
    : null

  if (duration !== null) {
    const seconds = parseInt(duration % 60)
    duration = (duration - seconds) / 60
    const minutes = parseInt(duration % 60)
    duration = (duration - minutes) / 60
    const hours = duration

    if (onlyMinutes) {
      duration = `${minutes}m`
    } else {
      duration = `${minutes}m ${seconds}s`
    }

    if (hours) {
      duration = `${hours}h ${duration}`
    }
  }

  return duration
}

export const getModulesList = (course) => {
  return (
    course.metadata &&
    course.metadata.crehanaCourse &&
    course.metadata.crehanaCourse.modules)
    ? course.metadata.crehanaCourse.modules
    : []
}

export const getModulesCount = (course) => {
  return getModulesList(course).length
}
