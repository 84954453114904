import PropTypes from 'prop-types'
import classNames from 'classnames'
import { get } from 'lodash'
import RawHTML from '../../../common/raw-html'

const Embed = ({ course, ...attrs }) => {
  const embedHTML = get(course, 'previewEmbed', '')

  attrs.className = classNames(
    attrs.className,
    [
      'relative'
    ]
  )
  attrs.style = {
    ...{
      width: '100%',
      height: '0',
      paddingBottom: '56.25%'
    },
    ...(attrs.style || {})
  }
  return <div
    {...attrs}>
    <div
      className={'absolute top-0 left-0 right-0 bottom-0'}>
      <RawHTML html={embedHTML} />
    </div>
  </div>
}

Embed.propTypes = {
  course: PropTypes.object
}

export default Embed
